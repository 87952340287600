import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../loader";
import { Context } from "@context/store";
import { useAxiosPrivate } from "@api/axios";
const AuthModal = ({ onClose }) => {
  const [{ formType, user }, dispatch] = useContext(Context);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    country: "uae",
  });
  const validateForm = () => {
    const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    const passwordRegex = /^.{6,}$/gm;
    if (
      formData.email &&
      formData.password &&
      (formType === "register" ? formData.country : true)
    ) {
      if (!formData.email.match(emailRegex)) {
        toast.error("Please enter a valid email address");
        return false;
      } else if (!formData.password.match(passwordRegex)) {
        toast.error("password must be at least 6 characters long");
        return false;
      } else return true;
    } else {
      toast.error("Please fill in all fields");
      return false;
    }
  };
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const axios = useAxiosPrivate();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formType === "register") {
      if (!validateForm()) return;
      const newUser = {
        email: formData.email,
        password: formData.password,
        country: formData.country,
      };

      setLoadingSignup(true);
      await axios
        .post("/api/guest/users/createUser", newUser)
        .then((response) => {
          dispatch({ type: "user", payload: response.data.user });
          localStorage.setItem("LIH-Token", response.data.user.token);
          toast.success("User register in successfully");
        })
        .catch((error) => {
          toast.error(error.message || "Signup failed");
        })
        .finally(() => setLoadingSignup(false));

      onClose();
    } else if (formType === "login") {
      const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      if (!formData.email.match(emailRegex)) {
        toast.error("Please enter a valid email address");
        return toast.error("Please enter a valid email address");
      }
      const user = {
        email: formData.email,
        password: formData.password,
      };

      setLoadingLogin(true);
      await axios
        .post("/api/guest/users/login", user)
        .then((response) => {
          dispatch({ type: "user", payload: response.data.user });
          localStorage.setItem("LIH-Token", response.data.user.token);
          toast.success("User logged in successfully");
          onClose();
        })
        .catch((error) => {
          dispatch({ type: "user", payload: null });

          toast.error(error.message || "Login failed");
        })
        .finally(() => setLoadingLogin(false));
    } else {
      const user = {
        email: formData.email,
      };
      setLoadingResetPassword(true);
      await axios
        .post("/api/guest/users/forgetPassword", user)
        .then((response) => {
          dispatch({ type: "user", payload: null });
          toast.success("Reset password link sent to your email");
          onClose();
        })
        .catch((error) => {
          dispatch({ type: "user", payload: null });

          toast.error(error.message || "Reset password failed");
        })
        .finally(() => setLoadingResetPassword(false));
    }
  };
  return (
    <div className="modal-content">
      <div className="body">
        <h4>
          {formType === "register"
            ? "Sign up for a new account"
            : formType === "login"
            ? "Login to your account"
            : "Reset your password"}
        </h4>
        <form>
          <div className="form-group">
            <input
              type="email"
              required={true}
              placeholder="Email address"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
            />
          </div>
          {formType !== "resetpassword" && (
            <div className="form-group">
              <input
                type="password"
                required={true}
                placeholder="Password"
                value={formData.password}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
              />
            </div>
          )}
          {formType === "register" && (
            <div className="form-group">
              <select
                value={formData.country}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    country: e.target.value,
                  }))
                }
              >
                <option value="uae">United Arab Emirates</option>
              </select>
            </div>
          )}
          <p>
            We'll call or text you to confirm your number. Standard message and
            data rates apply. <a href="#">Privacy policy</a>
          </p>
          {loadingSignup || loadingLogin || loadingResetPassword ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="submit-button create-account-button "
              onClick={handleFormSubmit}
            >
              {formType === "login"
                ? "Login"
                : formType === "resetpassword"
                ? "Reset password"
                : "Create account"}
            </button>
          )}
        </form>
        <p className="already my-5">
          {formType === "login" ? (
            <div className="login-links">
              <div>
                Don't have an account?{" "}
                <button
                  className="button-link blue"
                  onClick={() =>
                    dispatch({ type: "formType", payload: "register" })
                  }
                >
                  Sign up
                </button>
              </div>
              <button
                className="button-link blue"
                onClick={() =>
                  dispatch({ type: "formType", payload: "resetpassword" })
                }
              >
                Forgot password
              </button>
            </div>
          ) : formType === "resetpassword" ? (
            <>
              Already have an account?{" "}
              <button
                className="button-link blue"
                onClick={() => dispatch({ type: "formType", payload: "login" })}
              >
                Login
              </button>
            </>
          ) : (
            <>
              Already have an account?{" "}
              <button
                className="button-link blue"
                onClick={() => dispatch({ type: "formType", payload: "login" })}
              >
                Login
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default AuthModal;
