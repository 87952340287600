"use client";
import React, { useState, useEffect, useRef, useContext, use } from "react";
import Link from "next/link";

import BurgerMenu from "@icons/general/burger-menu";
import AuthModal from "../../modals/AuthModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faWallet } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import GenericModal from "../../modals/GenericModal";
import { AnimatePresence } from "framer-motion";
import { Context } from "@context/store";
import { useAxiosPrivate } from "@api/axios";
import { usePathname } from "next/navigation";
const Navbar = () => {
  const axios = useAxiosPrivate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [
    {
      authModalOpened: isModalVisible,
      formType,
      user: loggedUser,
      notifications,
    },
    dispatch,
  ] = useContext(Context);

  const dropdownRef = useRef(null);
  const walletRef = useRef(null);

  const isLoggedIn = loggedUser;

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
    if (walletRef.current && !walletRef.current.contains(event.target)) {
      setShowWallet(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible || showWallet) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible, showWallet]);

  const handleLoginClick = () => {
    setDropdownVisible(false);
    dispatch({ type: "formType", payload: "login" });
    dispatch({ type: "authModalOpened", payload: true });
  };

  const handleSignupClick = () => {
    setDropdownVisible(false);
    dispatch({ type: "formType", payload: "register" });
    dispatch({ type: "authModalOpened", payload: true });
  };
  const handleLogoutClick = () => {
    dispatch({ type: "user", payload: null });
    localStorage.removeItem("LIH-Token");
    toast.warning("You've been logged out");
  };

  const [scrolled, setScrolled] = useState(false);
  const isBaseRoute = usePathname() === "/";
  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);
  return (
    <div
      className={
        scrolled
          ? "navbar-container fixed"
          : `navbar-container ${isBaseRoute ? "" : "sub-base-route"}`
      }
    >
      <nav className="navbar">
        <div className="container ">
          <div className="navbar-container">
            <Link prefetch={true} href={"/"}>
              <div className="navbar-logo">
                <img src="/assets/imgs/logo.png" className="logo" alt="Logo" />
                <img
                  src="/assets/imgs/logo-dark.png"
                  className="logo-dark"
                  alt="Logo-dark"
                />
              </div>
            </Link>
            <div className="navbar-links">
              {/* <Link to="/">Explore</Link> */}
              <Link href="/ListYourProperty">List Your Property</Link>
              {/* <Link to="/join-us">Join Us</Link> */}
            </div>
            <div className="navbar-right">
              {/* {isLoggedIn && (
                <div className="wallet-button" ref={walletRef}>
                  <button
                    className="nav-btn wallet-button lg "
                    onClick={() => setShowWallet((prev) => !prev)}
                  >
                    <FontAwesomeIcon icon={faWallet} /> Wallet
                  </button>
                  {showWallet && <WalletModal />}
                </div>
              )} */}

              <Link
                className="nav-btn dark lg nl get-the-app-button"
                href={"/get-app"}
              >
                <FontAwesomeIcon icon={faDownload} /> Get the app
              </Link>
              {/* <button
                id="propertyTypeButton"
                className="nav-btn hover"
                onClick={() => setShowPropertyTypeSelector(true)}
              >
                {currentType === "hotel" ? <HotelIcon /> : <ApartmentIcon />}
              </button>
              <AnimatePresence>
                {showPropertyTypeSelect && (
                  <GenericModal
                    type="property"
                    onClose={setShowPropertyTypeSelector}
                  >
                    <>
                      <h4 className="title">What are you looking for?</h4>
                      <div className="property-types">
                        <button
                          className={`type ${
                            selectedType === "hotel" ? "selected" : ""
                          }`}
                          onClick={() => setSelectedType("hotel")}
                        >
                          {selectedType === "hotel" ? (
                            <CheckMark />
                          ) : (
                            <HotelIcon />
                          )}
                          Hotel
                        </button>
                        <button
                          className={`type ${
                            selectedType === "apartment" ? "selected" : ""
                          }`}
                          onClick={() => setSelectedType("apartment")}
                        >
                          {selectedType === "apartment" ? (
                            <CheckMark />
                          ) : (
                            <ApartmentIcon />
                          )}
                          Apartment
                        </button>
                      </div>
                      <div className="button-container">
                        <button
                          className="button-link"
                          onClick={() => setShowPropertyTypeSelector(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="submit-button"
                          onClick={handleChangeType}
                        >
                          Done
                        </button>
                      </div>
                    </>
                  </GenericModal>
                )}
              </AnimatePresence> */}
              <div
                className="nav-btn nogap"
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <BurgerMenu />
                <div className="nav-btn dark user-icon">
                  <FontAwesomeIcon icon={faUser} />
                  {notifications.filter((notification) => notification.new)
                    ?.length > 0 ? (
                    <div className="notifications-indicator">
                      {
                        notifications.filter((notification) => notification.new)
                          ?.length
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {dropdownVisible && (
                  <div className="user-dropdown">
                    {isLoggedIn ? (
                      <>
                        {loggedUser?.role == "Influencer" && (
                          <Link href="/Account/pages/Profile">Profile</Link>
                        )}
                        <div className="notification-link">
                          <Link href="/Notifications">
                            Notifications
                            {!notifications.filter(
                              (notification) => notification.new
                            )?.length > 0 ? (
                              <span className="notification-dot"></span>
                            ) : null}
                          </Link>
                        </div>
                        {isLoggedIn ? <Link href="/Chat">Chat</Link> : null}

                        {/* <Link to="/Transactions">Transactions</Link> */}
                        {/* <a href="#">Accommodation</a> */}
                        {/* <Link to="/wishList">Wishlist</Link> */}
                        <hr />
                        <Link href="/Account">Account</Link>
                        {/* <Link to="/Account/HelpCenter">Help center</Link> */}
                        <hr />
                        <a onClick={handleLogoutClick}>Log out</a>
                      </>
                    ) : (
                      <>
                        <Link href={"/"} className="navbar-link-mobile">
                          Explore
                        </Link>
                        {loggedUser && loggedUser.role == "Host" ? (
                          <a
                            className="navbar-link-mobile"
                            href="https://app.dev-server.one/dashboard"
                          >
                            Go to Dashboard
                          </a>
                        ) : (
                          <Link
                            className="navbar-link-mobile"
                            href="/ListYourProperty"
                          >
                            List Your Property
                          </Link>
                        )}
                        <Link className="navbar-link-mobile" href="/join-us">
                          Join Us
                        </Link>
                        <a onClick={handleSignupClick}>Sign up</a>
                        <a onClick={handleLoginClick}>Login</a>
                        <hr />
                        {/* <Link to="/Account/HelpCenter">Help center</Link> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isModalVisible && (
          <GenericModal
            onClose={() =>
              dispatch({ type: "authModalOpened", payload: false })
            }
          >
            <AuthModal
              formType={formType}
              onClose={() =>
                dispatch({ type: "authModalOpened", payload: false })
              }
            />
          </GenericModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
