import React from "react";
import { CircularProgress } from "@mui/material";

export default function Loader({ size, end }) {
  return (
    <div className={`loader ${end && "end"} `}>
      {/* MUI's CircularProgress expects the color prop to be a predefined string (like "primary", "secondary", etc.) */}
      <CircularProgress size={size ? size : 60} style={{ color: "#000" }} />
    </div>
  );
}
